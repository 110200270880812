<template>
  <section class="home">
    <h1>
      Objetivos:
      <span class="h1-underline"></span>
    </h1>

    <!-- FILTROS -->
    <v-row>
      <!-- Month Picker -->
      <v-col cols="4">
        <v-menu
          ref="menuMonthFilter"
          v-model="menuMonthFilter"
          :close-on-content-click="true"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="monthFilter"
              label="Mês"
              readonly
              v-bind="attrs"
              v-on="on"
              persistent-hint
              hint="Escolha o mês para análise"
              @change="changeInputMonth()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="monthFilter"
            type="month"
            no-title
            scrollable
            :max="dateMax"
            :min="dateMin"
            @input="changeInputMonth()"
          >
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <!-- DATA -->
    <v-row>
      <!-- KPI GERAL -->
      <v-col cols="3">
        <v-row>
          <v-col class="ma-0 pa-0">
            <v-card class="px-5 pb-8 pt-8 text-center">
              <h3 class="mb-6">KPI GERAL:</h3>
              <div class="d-flex">
                <v-progress-circular
                  :rotate="360"
                  :size="120"
                  :width="22"
                  :value="percentageKPI"
                  :color="colorKPI"
                  class="mb-10"
                >
                  <h1 class="font-weight-black ma-0 percent-text">
                    {{ percentageKPI }}%
                  </h1>
                </v-progress-circular>

                <div class="d-flex justify-center align-center ml-10">
                  <div class="text-center mr-6">
                    <h4 class="mb-3">Objetivo:</h4>
                    <p class="font-weight-light">{{ totalKPI }} €</p>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="text-center ml-6">
                    <h4 class="mb-3">Atingido:</h4>
                    <p class="font-weight-light">{{ atingidoKPI }} €</p>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <!-- ADICIONAR NOVOS OBJETIVOS -->
          <v-col class="ma-0 pa-0">
            <v-card class="px-5 py-8">
              <h3 class="mb-6">Adicionar novos objetivos:</h3>
              <div class="mb-6">
                <v-select
                  :items="filterConsultores"
                  dense
                  label="Consultor"
                  outlined
                  v-model="consultor"
                ></v-select>
                <v-text-field
                  label="valor"
                  placeholder="Valor"
                  outlined
                  type="numer"
                  dense
                  v-model="montante"
                ></v-text-field>
                <!-- ESCOLHA DO MÊS -->
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Mês"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    no-title
                    scrollable
                    :min="dateMax"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <!-- BTN PARA ADICIONAR: -->

                <div class="d-flex align-center justify-end">
                  <v-tooltip left color="rgba(0,0,0,0.2)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        fab
                        :dark="
                          !(
                            montante == 0 ||
                            consultor == '' ||
                            filterConsultores.length == 0
                          )
                        "
                        color="rgb(196, 194, 44)"
                        class="text-right"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          montante == 0 ||
                          consultor == '' ||
                          filterConsultores.length == 0
                        "
                        @click="adicionarMontante()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para adicionar o valor à tabela</span>
                  </v-tooltip>
                </div>
              </div>

              <h4 class="mb-6">Resumo dos Valores:</h4>

              <!-- TABELA -->
              <v-data-table
                :headers="headers"
                :items="objetivos"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn
                    x-small
                    fab
                    :dark="false"
                    color="gray"
                    class="text-right"
                    @click="excluirItem(item)"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                </template>
              </v-data-table>

              <p class="mt-5 text-center red--text" v-if="fraseError">
                Não foi possível salvar os objetivos.
              </p>

              <!-- BTN PARA SALVAR -->
              <div class="mt-10 d-flex align-center justify-end">
                <v-tooltip left color="rgba(0,0,0,0.2)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      :dark="!(objetivos.length == 0)"
                      color="rgb(196, 194, 44)"
                      class="text-right"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="objetivos.length == 0"
                      @click="salvarObjetivos()"
                    >
                      Salvar
                      <v-icon right :dark="!objetivos.length == 0" class="ml-2">
                        mdi-content-save
                      </v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Clique para salvar os novos objetivos dos consultores</span
                  >
                </v-tooltip>
              </div>

              <!-- FRASE DE SUCESSO: -->
              <v-snackbar
                v-model="sucess"
                :timeout="timeout"
                color="rgb(196, 194, 44)"
                dark
              >
                {{ fraseSucess }}</v-snackbar
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- POR CONSULTOR -->
      <v-col cols="9">
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 py-0">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in consultoresData"
                :key="i"
                v-show="i < consultoresData.length / 3"
                class="mb-5"
              >
                <v-expansion-panel-header>
                  <div class="d-flex flex-column justify-center align-center">
                    <h3 class="mb-5">{{ item.nome }}</h3>
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="20"
                      :value="item.value"
                      :color="item.color"
                      class="mb-5"
                    >
                      {{ item.value }}%
                    </v-progress-circular>
                    <div class="d-flex">
                      <div class="text-center mr-6">
                        <h4 class="mb-3">Objetivo:</h4>
                        <p v-if="item.total != 1">{{ item.total }} €</p>
                        <p v-else>Não definido</p>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="text-center ml-6">
                        <h4 class="mb-3">Atingido:</h4>
                        <p class="font-weight-light">{{ item.atingido }} €</p>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headersLeads"
                    :items="item.leads"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.detalhes="{ item }">
                      <LeadDetail :lead="item.detalhes" />
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col class="ma-0 py-0">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in consultoresData"
                :key="i"
                v-show="
                  i >= consultoresData.length / 3 &&
                  i < (2 * consultoresData.length) / 3
                "
                class="mb-5"
              >
                <v-expansion-panel-header>
                  <div class="d-flex flex-column justify-center align-center">
                    <h3 class="mb-5">{{ item.nome }}</h3>
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="20"
                      :value="item.value"
                      :color="item.color"
                      class="mb-5"
                    >
                      {{ item.value }}%
                    </v-progress-circular>
                    <div class="d-flex">
                      <div class="text-center mr-6">
                        <h4 class="mb-3">Objetivo:</h4>
                        <p v-if="item.total != 1">{{ item.total }} €</p>
                        <p v-else>Não definido</p>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="text-center ml-6">
                        <h4 class="mb-3">Atingido:</h4>
                        <p class="font-weight-light">{{ item.atingido }} €</p>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headersLeads"
                    :items="item.leads"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.detalhes="{ item }">
                      <LeadDetail :lead="item.detalhes" />
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col class="ma-0 py-0">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, i) in consultoresData"
                :key="i"
                v-show="i >= (2 * consultoresData.length) / 3"
                class="mb-5"
              >
                <v-expansion-panel-header>
                  <div class="d-flex flex-column justify-center align-center">
                    <h3 class="mb-5">{{ item.nome }}</h3>
                    <v-progress-circular
                      :rotate="360"
                      :size="100"
                      :width="20"
                      :value="item.value"
                      :color="item.color"
                      class="mb-5"
                    >
                      {{ item.value }}%
                    </v-progress-circular>
                    <div class="d-flex">
                      <div class="text-center mr-6">
                        <h4 class="mb-3">Objetivo:</h4>
                        <p v-if="item.total != 1">{{ item.total }} €</p>
                        <p v-else>Não definido</p>
                      </div>
                      <v-divider vertical></v-divider>
                      <div class="text-center ml-6">
                        <h4 class="mb-3">Atingido:</h4>
                        <p class="font-weight-light">{{ item.atingido }} €</p>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headersLeads"
                    :items="item.leads"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.detalhes="{ item }">
                      <LeadDetail :lead="item.detalhes" />
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import resources from "@/resources/fakeinativesleads";
import LeadDetail from "@/components/Lead_Detail";

export default {
  name: "Objetivos",
  components: {
    LeadDetail,
  },
  data: () => ({
    //Filtro:
    monthFilter: new Date().toISOString().substr(0, 7),
    dateMax: new Date().toISOString().substr(0, 7),
    moment: require("moment"),
    dateMin: 0,
    menuMonthFilter: false,
    items: [
      "Teresa Braga Costa",
      "Pedro Victor",
      "Bernardo Sotomayor",
      "Raquel Rocha",
    ],

    //KPI PRINCIPAL:
    financiado: ["18", "11", "40"],
    totalKPI: 0,
    atingidoKPI: 0,
    percentageKPI: 0,
    colorKPI: "",

    //Novos Objetivos
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    menu: false,
    montante: 0,
    consultor: "All",
    objetivos: [],
    headers: [
      {
        text: "Nome",
        align: "center",
        sortable: false,
        value: "nome",
      },
      { text: "Mês", value: "mes", sortable: true, align: "center" },
      { text: "Valor (€)", value: "valor", sortable: true, align: "center" },
      { text: "Excluir", value: "action", sortable: true, align: "center" },
    ],
    fraseError: false,
    fraseSucess: "",
    timeout: 3000,
    sucess: false,

    //Colunas Consultores:
    consultoresData: [
      {
        nome: "Teresa Braga Costa",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "teresa_braga_costa",
        color: "white",
      },
      {
        nome: "Pedro Victor",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "pedro_victor",
        color: "white",
      },
      {
        nome: "Bernardo Sotomayor",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "bernardo_sotomayor",
        color: "white",
      },
      {
        nome: "Raquel Rocha",
        value: 0, //porcentagem
        objetivo: 50000, //objetivo
        atingido: 0, //Valor total atingido
        leads: [],
        username: "raquel_rocha",
        color: "white",
      },
      {
        nome: "Fátima Barbosa",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "raquel_rocha",
        color: "white",
      },
      {
        nome: "Alexandre Jardim",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "raquel_rocha",
        color: "white",
      },
      {
        nome: "Valter Ponte",
        value: 0,
        objetivo: 50000,
        atingido: 0,
        leads: [],
        username: "raquel_rocha",
        color: "white",
      },
    ],
    headersLeads: [
      {
        text: "Lead Process",
        align: "start",
        sortable: false,
        value: "lead_process_id",
      },
      { text: "Produto", value: "produto" },
      { text: "Montante (€)", value: "montante" },
      { text: "Detalhes", value: "detalhes" },
    ],
  }),
  methods: {
    async prepareView() {
      await this.collectDataLeadsDisabled();

      await this.collectDataForFilters();

      await this.collectKPIS();

      await this.filtroResults();
      await this.filtrosConsultores();
    },
    async collectDataLeadsDisabled() {
      //Função responsável pela chamada das Leads Inativas:

      if (
        this.$store.state.leads_disabled.length == 0 ||
        this.$store.state.leads_disabled.includes("Error")
      ) {
        await this.$store.dispatch("collect_leads_disabled");

        // this.$store.commit("SET_GLOBAL_VARIABLE", {
        //   var: "leads_disabled",
        //   value: resources,
        // });
      }
    },
    async collectKPIS() {
      //Get all KPIS:
      await this.$store.dispatch("get_all_kpi", {
        month: this.dateMin.split("-")[1],
        year: this.dateMin.split("-")[0],
      });

      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      let allKpis = this.$store.state.allKpis;

      //Calculo do KPI Objetivo:
      this.totalKPI = allKpis
        .map((e) => (typeof e.kpi == "number" ? 0 : e.kpi.valor))
        .reduce(reducer);

      //Calculo dos objetivos por consultor
      for (let i in this.consultoresData) {
        let index = allKpis.findIndex(
          (e) => e.name == this.consultoresData[i].nome
        );
        if (index >= 0) {
          //Preciso essa transformação pois api devolve como string.
          this.consultoresData[i].total =
            typeof allKpis[index].kpi == "number"
              ? 1
              : allKpis[index].kpi.valor;
        }
      }
    },
    async changeInputMonth() {
      await this.collectDataLeadsDisabled();

      //Zerar os itens das tabelas:
      this.consultoresData = this.$store.state.consultores.map((e) => ({
        nome: e.name,
        value: 0,
        Objetivo: 0,
        atingido: 0,
        leads: [],
        username: e.username,
        color: "white",
      }));

      await this.collectKPIS();
      await this.filtroResults();
      await this.filtrosConsultores();
    },
    async collectDataForFilters() {
      this.dateMin =
        this.moment().subtract(1, "years").year() +
        "-" +
        this.moment().subtract(1, "years").month() +
        1;

      //Busca todos os consultores e adiciona All:
      await this.$store.dispatch("collect_consultores");
      this.items = this.$store.state.consultores.map((e) => e.name);

      //Formatar os consultoresData:
      this.consultoresData = this.$store.state.consultores.map((e) => ({
        nome: e.name,
        value: 0,
        Objetivo: 0,
        atingido: 0,
        leads: [],
        username: e.username,
        color: "white",
      }));

      this.items.unshift("All");
    },
    filtroResults() {
      //Todas as leads inativas (estágio final)
      let dados = this.$store.state.leads_disabled;

      let month = Number(this.monthFilter.split("-")[1]) - 1;
      let year = this.monthFilter.split("-")[0];

      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      //KPI Geral:
      this.atingidoKPI = dados.filter(
        (e) =>
          this.moment(e.last_interation).month() == month &&
          this.financiado.includes(e.current_step_id) &&
          this.moment(e.last_interation).year() == year
      );

      if (this.atingidoKPI.length > 0) {
        this.atingidoKPI = this.atingidoKPI
          .map((e) =>
            Object.keys(e.lead_details).length > 0
              ? this.cleanNumber(e.aux_1)
              : 0
          )
          .reduce(reducer);
      } else this.atingidoKPI = 0;

      this.percentageKPI = Math.floor((this.atingidoKPI / this.totalKPI) * 100);
      this.colorKPI = this.setColor(this.percentageKPI);
    },
    filtrosConsultores() {
      //Todas as leads inativas (estágio final)
      //let dados = resources;
      let dados = this.$store.state.leads_disabled;
      let month = Number(this.monthFilter.split("-")[1]) - 1;
      let year = this.monthFilter.split("-")[0];

      const reducer = (accumulator, currentValue) => accumulator + currentValue;

      for (let i in this.consultoresData) {
        //Get All leads:
        let leads = dados.filter(
          (e) =>
            this.moment(e.last_interation).month() == month &&
            this.financiado.includes(e.current_step_id) &&
            this.moment(e.last_interation).year() == year &&
            e.username == this.consultoresData[i].username
        );

        if (leads.length > 0) {
          this.consultoresData[i].leads = leads.map((e) => ({
            lead_process_id: e.lead_process_id,
            produto: this.nameProduct(e.produto),
            montante: e.aux_1,
            detalhes: e,
          }));

          this.consultoresData[i].atingido = leads
            .map((e) =>
              Object.keys(e.lead_details).length > 0
                ? this.cleanNumber(e.aux_1)
                : 0
            )
            .reduce(reducer);
        } else this.consultoresData[i].atingido = 0;

        this.consultoresData[i].value = Math.floor(
          (this.consultoresData[i].atingido / this.consultoresData[i].total) *
            100
        );

        this.consultoresData[i].color = this.setColor(
          this.consultoresData[i].value
        );

        //Calcular o valor atingido:
      }
    },
    setColor(value) {
      if (value < 30) {
        return "light-blue lighten-3";
      } else if (value >= 30 && value < 60) {
        return "cyan lighten-2";
      } else if (value >= 60 && value < 80) {
        return "teal lighten-2";
      } else {
        return "teal darken-3";
      }
    },
    cleanNumber(number) {
      if (number.includes("€")) {
        return Number(number.split("€")[0]);
      } else return Number(number);
    },
    nameProduct(type) {
      switch (type) {
        case "credito_consolidado":
          return "CC";

        case "credito_pessoal":
          return "CP";

        case "credito_habitacao":
          return "CH";

        case "seguro_de_vida":
          return "SS";

        default:
          return "CC";
      }
    },
    adicionarMontante() {
      let obj = this.objetivos.map((e) => e.nome);

      if (this.consultor == "All") {
        for (let i = 1; i < this.items.length; i++) {
          if (!obj.includes(this.items[i])) {
            this.objetivos.push({
              nome: this.items[i],
              mes: this.date,
              valor: this.montante,
            });
          }
        }
      } else {
        this.objetivos.push({
          nome: this.consultor,
          mes: this.date,
          valor: this.montante,
        });
      }

      this.montante = 0;
      this.consultor = "";
    },
    excluirItem(item) {
      this.objetivos = this.objetivos.filter((e) => e.nome != item.nome);
    },
    async salvarObjetivos() {
      let response = [];
      for (let i in this.objetivos) {
        let data = {
          username: this.getUsername(this.objetivos[i].nome),
          kpi: `{valor:${this.objetivos[i].valor}}, month:${
            this.objetivos[i].mes.split("-")[1]
          },year:${this.objetivos[i].mes.split("-")[0]}`,
        };

        await this.$store.dispatch("set_kpi", data);
        response.push(this.$store.state.resultApi);
      }

      //Erro para o cliente:
      this.fraseError = response.includes("KO");
      this.sucess = !this.fraseError;
      this.fraseSucess = "Foram alterados com sucesso os objetivos.";

      //Caso tudo Ok:
      if (!this.fraseError) {
        await this.collectKPIS();
        await this.changeInputMonth();
      }

      this.objetivos = [];
    },
    getUsername(name) {
      return name.toLowerCase().split(" ").join("_");
    },
  },

  async created() {
    this.prepareView();
  },
  computed: {
    filterConsultores: function () {
      if (
        this.objetivos.length > 0 &&
        this.objetivos.length != this.items.length - 1
      ) {
        let obj = this.objetivos.map((e) => e.nome);
        return this.items.filter((e) => !obj.includes(e));
      } else if (this.objetivos.length == 0) return this.items;
      else return [];
    },
  },
};
</script>

<style scoped>
.home {
  width: calc(100vw - 256px);
  margin-left: 256px;
  padding: 40px;
  padding-top: 50px;
  margin-bottom: 50px;
}
h1 {
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

.percent-text {
  font-size: 20px;
}
</style>